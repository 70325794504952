export default [
  {
    key: 'leaveTypeId',
    label: 'field.leaveType',
    rules: 'required',
    type: 'async-single-selection',
    repository: 'leaveType',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'year',
    label: 'field.year',
    rules: 'required',
    type: 'year-picker',
  },
  {
    key: 'allocateQuantity',
    label: 'field.allocateQuantity',
    rules: 'required|decimal:2',
    type: 'currency',
  },
  {
    key: 'maxForwardQuantity',
    label: 'field.maxForwardQuantity',
    rules: 'required|decimal:2|min_value:0',
    type: 'currency',
  },
  {
    key: 'remark',
    label: 'field.remark',
    type: 'textarea',
    cols: 12,
  },
]
